import React from 'react'
import { animated, config, useSpring } from 'react-spring'
import Img from 'gatsby-image'
import { graphql, useStaticQuery, Link } from 'gatsby'
import styled from 'styled-components'
import { readableColor, transparentize } from 'polished'
import Layout, { MyPBox } from '../components/layout'
import { AnimatedBox, Button } from '../elements'
import SEO from '../components/SEO'
import { ChildImageSharp } from '../types'
import IconHouse from '../icon_house.svg'
import IconDoor from '../icon_door.svg'
import IconPhone from '../icon_phone.svg'
import IconEmail from '../icon_email.svg'
import IconAt from '../icon_at.svg'
import IconGlobe from '../icon_globe.svg'

const cfg = require('../../config')

interface QueryResult {
  imageCornwall: ChildImageSharp
  imageDESY: ChildImageSharp
}

const Content = styled(AnimatedBox)<{ bg: string }>`
  background-color: ${props => transparentize(0.9, props.bg)};

  .photoCaption {
    font-size: 0.8rem;
    text-align: center;
    font-style: italic;
  }

  h2 {
    font-size: 1.5rem;
  }
`

const AreaTwoCol = styled(animated.div)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-template-areas: 'box-text box-photo';

  @media (max-width: ${props => props.theme.breakpoints[0]}) {
    grid-template-columns: 1fr;

    grid-template-areas:
      'box-text'
      'box-photo';
  }
`

const BoxText = styled(MyPBox)`
  grid-area: box-text;
`
const BoxPhoto = styled(MyPBox)`
  grid-area: box-photo;
`

const PButton = styled(Button)<{ color: string }>`
  background-color: transparent;
  border: 2px solid #000000;
`

const PButtonEmpty = styled(Button)<{ color: string }>`
  background-color: transparent;
`

const SvgIcon = styled.img<{ size: string }>`
  width: ${props => props.size};
  height: ${props => props.size};
`

const About = () => {
  const data: QueryResult = useStaticQuery(query)

  const titleAnimation = useSpring({
    config: config.slow,
    delay: 300,
    from: { opacity: 0 },
    to: { opacity: 1 },
  })
  const contentAnimation = useSpring({
    config: config.slow,
    delay: 600,
    from: { opacity: 0 },
    to: { opacity: 1 },
  })
  const imagesAnimation = useSpring({
    config: config.slow,
    delay: 800,
    from: { opacity: 0 },
    to: { opacity: 1 },
  })

  return (
    <Layout>
      <SEO title={`About${cfg.siteTitleSuffix}`} desc="About page" />
      <AnimatedBox style={titleAnimation} py={[6, 6, 6, 8]} px={[6, 6, 8, 6, 8]}>
        <h1>About</h1>
      </AnimatedBox>
      <Content style={contentAnimation} bg={cfg.backgroundColor} py={8}>
        <MyPBox pb={[1]}>
          <p>
            As you maybe already read on the front page, my name is Florian Blum and I live in{' '}
            <a href="https://en.wikipedia.org/wiki/Mülheim">Mülheim an der Ruhr</a> in Germany.
          </p>
          <p>
            After studying applied computer science at the{' '}
            <a href="https://www.uni-due.de">University of Duisburg-Essen</a> I'm currently working at the{' '}
            <a href="https://se.wiwi.uni-due.de">chair of Prof. Dr. Volker Gruhn</a> where I do my PhD in the area of
            Blockchain-oriented Software Engineering and Blockchain Software Architectures.
          </p>
          <p>
            If you are interested in my research about Blockchain technology, have a look at the{' '}
            <Link to="/research" aria-label="Visit research page">
              research page
            </Link>
            .
          </p>
        </MyPBox>
        <MyPBox pb={[1]}>
          <h2>Name</h2>
          <p>
            My name is Florian Blum since I got married. Previously my name was Florian Wessling (or more precisely
            "Florian Weßling", with the special character <a href="https://en.wikipedia.org/wiki/%C3%9F">Eszett</a>).
            I'm mentioning this because my previous research papers are published with my old name and I want to make
            sure this website can still be found. ;)
          </p>
          <p>
            Do you know that my first and last name now perfectly fit together? Here is a funny anecdote: "Florian" is
            derived from the Latin word <i>"florere"</i>, meaning <i>"to blossom"</i> or <i>"to bloom"</i>, while{' '}
            <i>"Blum"</i> is a shortened form of the German word <i>"Blume"</i> which means <i>"flower"</i>. Isn't that
            nice? 😊
          </p>
        </MyPBox>
        <MyPBox pb={0}>
          <h2 style={{ marginBottom: 0 }}>Office Address</h2>
        </MyPBox>
        <MyPBox style={imagesAnimation}>
          <p>
            <PButtonEmpty px={4}>
              <SvgIcon src={IconHouse} size="2rem" />
            </PButtonEmpty>
            University of Duisburg-Essen, Schützenbahn 70, 45127 Essen, Germany
          </p>
          <p>
            <PButtonEmpty px={4}>
              <SvgIcon src={IconDoor} size="2rem" />
            </PButtonEmpty>
            Room SA022
          </p>
          <p>
            <PButtonEmpty px={4}>
              <SvgIcon src={IconPhone} size="2rem" />
            </PButtonEmpty>
            +49 201 183 7047
          </p>
          <p>
            <PButtonEmpty px={4}>
              <SvgIcon src={IconEmail} size="2rem" />
            </PButtonEmpty>
            florian.blum <SvgIcon src={IconAt} size="1rem" /> uni-due.de
          </p>
          <p>
            <PButtonEmpty px={4}>
              <SvgIcon src={IconGlobe} size="2rem" />
            </PButtonEmpty>
            <a href="https://se.wiwi.uni-due.de/florian-blum/">https://se.wiwi.uni-due.de/florian-blum/</a>
          </p>
        </MyPBox>
        {/*<MyPBox style={imagesAnimation}>
          <Img
            alt="TODO"
            key={data.imageCornwall.childImageSharp.fluid.src}
            fluid={data.imageCornwall.childImageSharp.fluid}
          />
          <p className="photoCaption">Climbing "Brown Willy" – the highest hill in Cornwall, England (2018)</p>
        </MyPBox>*/}
      </Content>
    </Layout>
  )
}

export default About

const query = graphql`
  query AboutQuery {
    imageCornwall: file(sourceInstanceName: { eq: "images" }, name: { eq: "photo-cornwall" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    imageDESY: file(sourceInstanceName: { eq: "images" }, name: { eq: "about-flo-at-desy" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`
